<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      width="800px"
      :mask-closable="false"
      title="发送办理相关手续告知书"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 1 }"
        :wrapper-col="{ span: 20 }"
        @submit="handleSubmit"
      >
        <a-form-item>
          <wang-editor-frequently-asked-question
            v-decorator="['content', {
              rules: [
                { max: 2000, message: '最多2000个字符' },
                { required: true, message: '请输入发送办理相关手续告知书' }
              ]
            }]"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button class="ant-btn-default" @click="closeModal">
          关闭
        </a-button>
        <a-button class="ant-btn-primary" @click="handleSubmit">
          发送
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>

import WangEditorFrequentlyAskedQuestion from '@/components/Editor/WangEditor'
import { findProcessNotifications } from '@/api/process_notification'
import { sendProcessNotificationNotice } from '@/api/order'

export default {
  name: 'IndexProcessNotification',
  components: {
    WangEditorFrequentlyAskedQuestion
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'frequently_asked_question_edit' }),
      submitting: false
    }
  },
  created() {
    this.initEditor()
  },
  methods: {
    initEditor() {
      // 访问编辑框的时候加载以前保存的内容
      findProcessNotifications().then((res) => {
        this.form.setFieldsValue({
          content: res.data.content
        })
      })
    },
    closeModal() {
      this.$emit('completed')
      this.isShow = false
    },
    // 保存事件
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          sendProcessNotificationNotice(Object.assign({ order_id: this.id }, values)).then((res) => {
            this.submitting = false
            this.isShow = false
            // 告知父组件已完成
            this.$emit('completed')
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.set-button-center {
  text-align: center;
}
</style>
